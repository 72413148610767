
  /* Styles for desktop screens */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

html, body {
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Ensure the footer stays at the bottom */
.footer-basic {
  margin-top: auto;
}




  .container {

    margin: 0 auto;
  }

  .logo3 {
    position: relative;
    height: auto;
    margin: 0 auto;
    margin-top: 10pc;
    justify-content: center;
   
  
  }
.nav-logo{
  position: relative;
  height: 70px;

}
  .root,
  html,
  body {
    line-height: 3;
    background-color: #76a5af;
    -webkit-text-size-adjust: 100%;
    font-family: "UniSpace";
    height: 100%;
    position: relative;
    color: black;
  }

  .nav,
  .navbar,
  .li,
  .a,
  .nav-link,
  .nav-link :active {
    color: white !important;
    font-weight: bolder;
    font-size: larger;
    
   
  }
  
  .masthead{
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .masthead-title {
    position: relative;
    margin-top: pc;
    font-size: 45px;
    line-height: 2;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .ul-child {
    line-height: 3;
    text-indent: 50px;
    font-size: large;

  }

  .section-idea {
    position: relative;
    margin-top: 6pc;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .ul-child::first-letter {
    font-size: 2pc;
    line-height: normal;
  }

  .bulb-img {
    position: relative;
    top: 0pc;
    height: 300px;
  }

  .logo4 {
    position: relative;
    border-radius: 2%;
    height: auto;
    max-width: 450px;
  
  }

  .div-idea {
    width: 900px;
    height: auto;
    justify-content: center;
  }
  .section-contact{
    position: relative;
    margin-top: 10pc;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .contact-div {
    position: relative;
    font-size: large;
    text-indent: 50px;
    justify-content: center;
  
  }

  .contact-paragraph {
    position: relative;
    width: 800px;
    left: 5pc;
  
    
  }

  .contact-paragraph::first-letter {
    font-size: 2pc;
    line-height: normal;
  }

  .button,
  .build-btn {
    background-color: white;
    padding: 5px;
    border-color: #353535;
    border-radius: 3%;
    position: relative;
    left: 16pc;
   

  }

  .about {
    font-size: large;
    line-height: 3;
    width: 1000px;
  }

  .about-2 {
    columns: 1;
    font-size: large;
    text-indent: 50px;
    width: 450px;
  }

  .section-owner {
    margin-top: 10pc;
    position: relative;
    display: flex;
    justify-content: center;
    
  }

  .about::first-letter {
    font-size: 2pc;
    line-height: normal;
  }

  .about-2::first-letter {
    font-size: 2pc;
    line-height: normal;
  }

  .logo2 {
    height: 130px;
  }

  .owner {
    position: relative;
    font-size: large;
    width: 600px;
  
  }



  .owner-title {
    position: relative;
  text-align: center;
  text-align: justify;
  font-size: 20px;
    left: 31pc;
    top: -42pc;
  
  }

  .headshot {
    position: relative;
    height: auto;
    max-width: 360px;
    border-radius: 2%;
    left: 31pc;
    margin-top: -55pc;
 
  
  }

  .rwengineer {
    font-size: 490%;
  }

  .contactcard {
    position: absolute;
    top: 35%;
    width: 800px;
    left: 1pc;
  }

  .hero-image {
    background-image: url("./images/ratwerkslogo.png");
    opacity: 10%;
    height: 1350px;
    width: 1800px;
    margin: 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .container {
    width: 100%;
  }

  footer,
  .footer-basic,
  .copyright {
    padding: 10px 0;
    background-color: #353535;
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
    margin-bottom: 0;
    width: 100%;
  }



/* Media query for smaller screens (max-width: 767px) */
@media (max-width: 767px) {
  /* Adjust the logo margin for better spacing on mobile */
  .logo3 {
    margin-top: 40%;
    margin-left: 15%;
    max-width: 270px; /* Reduce logo size on mobile */
  }

  /* Adjust the font size and line-height for better readability on mobile */
  .masthead-title {
    font-size: 20px;
    line-height: 1.5;
  }

  /* Adjust the width for better spacing on mobile */
  .div-idea {
    width: 100%;
  }
  .section-idea{
    width: 100%;
  }
  .ul-child{
    font-size: x-small;
    width: 220px;
  }
  .bulb-img{
    max-height:210px;
  }

  .section-contact {
    flex-direction: column;
    align-items: center;
  }

  .contact-paragraph {
    position: relative;
    margin-top: 1rem;
    text-align: justify;
    font-size: x-small;
    
    margin-left: -10pc;
    max-width: 300px;
  }

  .logo4 {
    max-width: 300px;
    top:-4pc
  }
  .build-btn{
    margin-left:-21pc ;
    height: 50px;
    font-size: 15px;
  }

  .div-idea {
    flex-direction: column;
    align-items: center;
  }


  /* Adjust the font size and line-height for better readability on mobile */
  .about {
    width: 300px;
    font-size: small;
    margin-left: 3pc;
  }
  .about::first-letter {
    font-size: 2pc;
    line-height: normal;
  }

  /* Adjust the font size and line-height for better readability on mobile */
  .about-2 {
    width:300px ;
    font-size: small;
    margin-left: 3pc;
  }
  .section-owner{
    flex-direction: column;
    align-items: center;
  
  }
  .div-owner{
    width: 100%;
  }

  .owner-title {
    position: relative;
  text-align: center;
  text-align: justify;
  font-size: medium;
  margin-top: 2pc;
    left: 3pc;
    top: -1pc;
  
  }
  .headshot {
    left: 3pc;
    bottom: 0pc;
    margin-top: 0;
    max-width: 300px; /* Reduce image size on mobile */
  }

  /* Adjust the font size for better readability on mobile */
  .rwengineer {
    font-size: 300%;
  }

  /* Adjust the width for better spacing on mobile */
    
  .contactcard {
    width: 300px;
    position: relative;
    top: 10pc;
    left: -17pc;
  }/* Adjust the height and width for better spacing on mobile */
  .hero-image {
    height: 800px;
    width: 100%;
  }

  /* Adjust the font size and line-height for better readability on mobile */
  .nav,
  .navbar,
  .li,
  .a,
  .nav-link,
  .nav-link :active {
    font-size: 16px;
    line-height: 2;
  }
}
